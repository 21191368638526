import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { EventsData, EventTheme } from 'interfaces/Events/Events';

import { SwiperSlide } from 'swiper/react';
import Slider from 'components/blocks/Slider/Slider';
import EventsGallery from 'components/blocks/Events/EventsGallery';

import styles from './Events.module.scss';

interface Events {
  eventsArray: EventsData[];
  title?: string;
  withGallery?: boolean;
  customClassName?: string;
  theme?: EventTheme;
}

const Events: React.FC<Events> = ({
  eventsArray,
  title,
  withGallery = true,
  customClassName = 'content',
  theme = 'light',
}) => {
  const { t } = useTranslation('events');

  return (
    <section className={cn(styles.events, customClassName)} id='events'>
      <Slider
        title={title ? title : t('events.title')}
        spaceBetween={15}
        breakpoints={{
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 12,
          },
        }}
        className={styles.events__slider}
      >
        {eventsArray.map((item: EventsData, i) => {
          return (
            <SwiperSlide
              key={i}
              className={cn(styles.events__group, styles[item.type])}
            >
              {item.elems.map((elem, e) => (
                <EventsGallery
                  key={`${i}${e}`}
                  id={elem.id}
                  text={elem.text}
                  type={item.type}
                  withGallery={withGallery}
                  theme={theme}
                />
              ))}
            </SwiperSlide>
          );
        })}
      </Slider>
    </section>
  );
};

export default Events;
