import React from 'react';

const SliderArrow: React.FC = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.3643 9.42401L20.2499 11.3096L15.5359 16.0237L20.2499 20.7377L18.3643 22.6233L11.7646 16.0237L18.3643 9.42401Z'
        fill='#2A2A2A'
      />
    </svg>
  );
};

export default SliderArrow;
