export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const result: T[][] = [],
    length = array.length;
  for (let i = 0; i < length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
};

export const chunkArrayColumns = <T>(data: T[] = [], columns = 1): T[][] => {
  return data.reduce((array, currentValue, index) => {
    const newIndex = Math.abs(index % columns);

    if (!array[newIndex]) {
      array[newIndex] = [];
    }

    array[newIndex].push(currentValue);

    return array;
  }, []);
};
