import React, { useState, useMemo, useRef, useEffect } from 'react';
import cn from 'classnames';
import { Swiper } from 'swiper/react';
import ISwiper from 'swiper/types/swiper-class';
import SwiperCore, { EffectFade } from 'swiper';
import { useSlider } from 'helpers/hooks/slider';
import { useIsMobile } from 'helpers/hooks/isMobile';

import styles from './VerticalSlider.module.scss';
import SliderArrow from 'components/blocks/Icons/Arrows/SliderArrow';

interface VerticalSliderProps {
  heading: string;
  text: React.ReactNode;
  spaceBetween: number;
  link?: React.ReactNode;
  rowReverse?: boolean;
  getActiveSlideHeight?(slide: number): number;
  customSliderClass?: string;
  customInfoClass?: string;
}

interface VerticalSliderStyles extends React.CSSProperties {
  '--slider-height': string;
}

const VerticalSlider: React.FC<VerticalSliderProps> = ({
  heading,
  text,
  link = null,
  rowReverse,
  children,
  spaceBetween,
  getActiveSlideHeight,
  customSliderClass,
  customInfoClass,
}) => {
  const isMobile = useIsMobile(767);
  const [maxHeight, setMaxHeight] = useState(1040);
  const swiperRef = useRef<ISwiper>();
  const animationFrame = useRef(0);
  const { prevArrow, nextArrow, fraction, sliderRenewParams } = useSlider();

  SwiperCore.use([EffectFade]);

  const elementStyles: VerticalSliderStyles = useMemo(
    () => ({ '--slider-height': `${maxHeight}px` }),
    [maxHeight]
  );

  useEffect(() => {
    let startTimestamp: number | null = null;
    const duration = 350,
      threshold = 50;

    const animate = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      if (elapsed > threshold) {
        swiperRef.current.update();
        startTimestamp = timestamp;
      }

      if (elapsed < duration) {
        animationFrame.current = window.requestAnimationFrame(animate);
      }
    };

    animationFrame.current = window.requestAnimationFrame(animate);

    return () => {
      window.cancelAnimationFrame(animationFrame.current);
    };
  }, [maxHeight]);

  return (
    <div
      className={cn('container', styles.slider__container, {
        [styles.slider__containerReverse]: rowReverse,
      })}
      //style={elementStyles}
    >
      <article className={customInfoClass || styles.slider__info}>
        <div className={styles.slider__nav}>
          <div
            ref={prevArrow}
            className={cn(styles.slider__navArrow, styles.slider__navArrowPrev)}
          >
            <SliderArrow />
          </div>
          <div ref={fraction} className={styles.slider__fraction} />
          <div
            ref={nextArrow}
            className={cn(styles.slider__navArrow, styles.slider__navArrowNext)}
          >
            <SliderArrow />
          </div>
        </div>
        <h2 className={styles.slider__heading}>{heading}</h2>
        <div className={styles.slider__text}>{text}</div>
        {!isMobile && <div className={styles.slider__link}>{link}</div>}
      </article>
      <Swiper
        navigation={{
          prevEl: prevArrow.current,
          nextEl: nextArrow.current,
        }}
        pagination={{
          type: 'custom',
          el: fraction.current,
          renderCustom: (_, current, total) => {
            return `${current} / ${total}`;
          },
        }}
        loop
        autoHeight={true}
        onInit={sliderRenewParams}
        onBreakpoint={sliderRenewParams}
        className={customSliderClass || styles.slider}
        //direction='vertical'
        allowTouchMove={false}
        spaceBetween={spaceBetween}
        onTransitionEnd={swiper => {
          //setMaxHeight(getActiveSlideHeight(swiper.realIndex));
          swiperRef.current = swiper;
        }}
        effect='fade'
        fadeEffect={{
          crossFade: true,
        }}
      >
        {children}
      </Swiper>
      {isMobile && link}
    </div>
  );
};

export default VerticalSlider;
