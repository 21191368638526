import React from 'react';
import cn from 'classnames';

import { IReviewItem } from 'services/homepageService';

import styles from './ReviewItem.module.scss';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';

interface ReviewItemLinkProps {
  link: string;
}

const ReviewItemLink: React.FC<ReviewItemLinkProps> = ({ link, children }) => {
  return (
    <Link href={link}>
      <a className='link text-green' target='_blank' rel='noopener noreferrer'>
        {children}
      </a>
    </Link>
  );
};

const ReviewItem: React.FC<IReviewItem> = ({
  photo,
  text,
  name,
  place,
  flag,
  links,
}) => {
  const { t } = useTranslation('reviews');

  return (
    <div className={styles.reviewItem}>
      <p className={styles.reviewItem__text}>
        <Trans
          t={t}
          i18nKey={text}
          components={links.map((link, i) => (
            <ReviewItemLink key={i} link={link} />
          ))}
        />
      </p>
      <div className={styles.reviewItem__info}>
        <div className={styles.reviewItem__photoContainer}>
          <div className={styles.reviewItem__photo}>
            <img
              className={cn('img', {
                ['lazyload']: photo,
              })}
              src='/images/reviews/reviews-placeholder.jpg'
              data-src={photo ? `/images/reviews/${photo}` : ''}
              alt={name}
            />
          </div>
          {flag && (
            <div className={styles.reviewItem__flag}>
              <img
                className='img'
                src={`/images/reviews/flags/${flag}`}
                alt='flag'
              />
            </div>
          )}
        </div>
        <div className={styles.reviewItem__person}>
          <p className={styles.reviewItem__name}>{name}</p>
          <span className={styles.reviewItem__place}>
            <Trans
              t={t}
              i18nKey={place}
              components={links.map((link, i) => (
                <ReviewItemLink key={i} link={link} />
              ))}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;
