export const images = {
  1: {
    imageMain: 'event-1.png',
    imagesInner: ['/event-1/image-1.jpg', '/event-1/image-2.jpg'],
  },
  2: {
    imageMain: 'event-2.png',
    imagesInner: ['/event-2/image-1.jpg', '/event-2/image-2.jpg'],
  },
  3: {
    imageMain: 'event-3.jpg',
    imagesInner: ['/event-3/image-1.jpg'],
  },
  4: {
    imageMain: 'event-4.png',
    imagesInner: ['/event-4/image-1.jpg', '/event-4/image-2.jpg'],
  },
  5: {
    imageMain: 'event-5.jpg',
    imagesInner: ['/event-5/image-1.jpg', '/event-5/image-2.jpg'],
  },
  6: {
    imageMain: 'event-6.png',
    imagesInner: ['/event-6/image-1.jpg', '/event-6/image-2.jpg'],
  },
  7: {
    imageMain: 'event-7.jpg',
    imagesInner: [
      '/event-7/image-1.jpg',
      '/event-7/image-2.jpg',
      '/event-7/image-3.jpg',
      '/event-7/image-5.jpg',
      '/event-7/image-6.jpg',
    ],
  },
  8: {
    imageMain: 'event-8.png',
    imagesInner: ['/event-8/image-1.jpg', '/event-8/image-2.jpg'],
  },
  9: {
    imageMain: 'event-9.jpg',
    imagesInner: [
      '/event-9/image-1.jpg',
      '/event-9/image-2.jpg',
      '/event-9/image-3.jpg',
      '/event-9/image-4.jpg',
      '/event-9/image-5.jpg',
      '/event-9/image-6.jpg',
    ],
  },
  10: {
    imageMain: 'event-10.png',
    imagesInner: [
      '/event-10/image-1.jpg',
      '/event-10/image-2.jpg',
      '/event-10/image-3.jpg',
    ],
  },
  11: {
    imageMain: 'event-11.png',
    imagesInner: ['/event-11/image-1.jpg', '/event-11/image-2.jpg'],
  },
  12: {
    imageMain: 'event-12.png',
    imagesInner: [
      '/event-12/image-1.jpg',
      '/event-12/image-2.jpg',
      '/event-12/image-3.jpg',
    ],
  },
  13: {
    imageMain: 'event-13.png',
    imagesInner: ['/event-13/image-1.jpg', '/event-13/image-2.jpg'],
  },
  14: {
    imageMain: 'event-14.png',
    imagesInner: ['/event-14/image-1.jpg', '/event-14/image-2.jpg'],
  },
  15: {
    imageMain: 'event-15.png',
    imagesInner: ['/event-15/image-1.jpg', '/event-15/image-2.jpg'],
  },
  16: {
    imageMain: 'event-16.png',
    imagesInner: [
      '/event-16/image-1.jpg',
      '/event-16/image-2.jpg',
      '/event-16/image-3.jpg',
    ],
  },
  17: {
    imageMain: 'event-17.png',
    imagesInner: [
      '/event-17/image-1.jpg',
      '/event-17/image-2.jpg',
      '/event-17/image-3.jpg',
    ],
  },
  18: {
    imageMain: 'event-18.png',
    imagesInner: ['/event-18/image-1.jpg', '/event-18/image-2.jpg'],
  },
  19: {
    imageMain: 'event-19.jpg',
    imagesInner: [
      '/event-19/image-1.jpg',
      '/event-19/image-2.jpg',
      '/event-19/image-3.jpg',
      '/event-19/image-4.jpg',
      '/event-19/image-5.jpg',
      '/event-19/image-6.jpg',
      '/event-19/image-7.jpg',
      '/event-19/image-8.jpg',
      '/event-19/image-9.jpg',
      '/event-19/image-10.jpg',
    ],
  },
  20: {
    imageMain: 'event-20.jpg',
    imagesInner: [
      '/event-20/image-1.jpg',
      '/event-20/image-2.jpg',
      '/event-20/image-3.jpg',
      '/event-20/image-4.jpg',
      '/event-20/image-5.jpg',
      '/event-20/image-6.jpg',
      '/event-20/image-7.jpg',
      '/event-20/image-8.jpg',
      '/event-20/image-9.jpg',
    ],
  },
  21: {
    imageMain: 'event-21.jpg',
    imagesInner: [
      '/event-21/image-1.jpg',
      '/event-21/image-2.jpg',
      '/event-21/image-3.jpg',
      '/event-21/image-4.jpg',
      '/event-21/image-5.jpg',
      '/event-21/image-6.jpg',
    ],
  },
  22: {
    imageMain: 'event-22.jpg',
    imagesInner: [
      '/event-22/image-1.jpg',
      '/event-22/image-2.jpg',
      '/event-22/image-3.jpg',
      '/event-22/image-4.jpg',
      '/event-22/image-5.jpg',
      '/event-22/image-6.jpg',
      '/event-22/image-7.jpg',
      '/event-22/image-8.jpg',
      '/event-22/image-9.jpg',
      '/event-22/image-10.jpg',
      '/event-22/image-11.jpg',
    ],
  },
  23: {
    imageMain: 'event-23.jpg',
    imagesInner: [
      '/event-23/image-1.jpg',
      '/event-23/image-2.jpg',
      '/event-23/image-3.jpg',
      '/event-23/image-4.jpg',
      '/event-23/image-5.jpg',
      '/event-23/image-6.jpg',
      '/event-23/image-7.jpg',
      '/event-23/image-8.jpg',
      '/event-23/image-9.jpg',
      '/event-23/image-10.jpg',
    ],
  },
  24: {
    imageMain: 'event-24.jpg',
    imagesInner: ['/event-24/image-1.jpg', '/event-24/image-2.jpg'],
  },
  25: {
    imageMain: 'event-25.jpg',
    imagesInner: [
      '/event-25/image-1.jpg',
      '/event-25/image-2.jpg',
      '/event-25/image-3.jpg',
      '/event-25/image-4.jpg',
      '/event-25/image-5.jpg',
    ],
  },
  26: {
    imageMain: 'event-26.jpg',
    imagesInner: [
      '/event-26/image-1.jpg',
      '/event-26/image-2.jpg',
      '/event-26/image-3.jpg',
      '/event-26/image-4.jpg',
      '/event-26/image-5.jpg',
      '/event-26/image-6.jpg',
      '/event-26/image-7.jpg',
    ],
  },
  27: {
    imageMain: 'event-27.jpg',
    imagesInner: [
      '/event-27/image-1.jpg',
      '/event-27/image-2.jpg',
      '/event-27/image-3.jpg',
      '/event-27/image-4.jpg',
      '/event-27/image-5.jpg',
    ],
  },
  28: {
    imageMain: 'event-28.jpg',
    imagesInner: [
      '/event-28/image-1.jpg',
      '/event-28/image-2.jpg',
      '/event-28/image-3.jpg',
      '/event-28/image-4.jpg',
      '/event-28/image-5.jpg',
      '/event-28/image-6.jpg',
      '/event-28/image-7.jpg',
      '/event-28/image-8.jpg',
      '/event-28/image-9.jpg',
      '/event-28/image-10.jpg',
      '/event-28/image-11.jpg',
    ],
  },
  29: {
    imageMain: 'event-29.jpg',
    imagesInner: [
      '/event-29/image-1.jpg',
      '/event-29/image-2.jpg',
      '/event-29/image-3.jpg',
      '/event-29/image-4.jpg',
      '/event-29/image-5.jpg',
      '/event-29/image-6.jpg',
    ],
  },
  30: {
    imageMain: 'event-30.jpg',
    imagesInner: [
      '/event-30/image-1.jpg',
      '/event-30/image-2.jpg',
      '/event-30/image-3.jpg',
      '/event-30/image-4.jpg',
      '/event-30/image-5.jpg',
      '/event-30/image-6.jpg',
      '/event-30/image-7.jpg',
      '/event-30/image-8.jpg',
      '/event-30/image-9.jpg',
      '/event-30/image-10.jpg',
      '/event-30/image-11.jpg',
      '/event-30/image-12.jpg',
      '/event-30/image-13.jpg',
      '/event-30/image-14.jpg',
      '/event-30/image-15.jpg',
      '/event-30/image-16.jpg',
      '/event-30/image-17.jpg',
      '/event-30/image-18.jpg',
    ],
  },
  31: {
    imageMain: 'event-31.jpg',
    imagesInner: ['/event-31/image-1.jpg'],
  },
  32: {
    imageMain: 'event-32.jpg',
    imagesInner: ['/event-32/image-1.jpg'],
  },
  33: {
    imageMain: 'event-33.jpg',
    imagesInner: ['/event-33/image-1.jpg'],
  },
  34: {
    imageMain: 'event-34.jpg',
    imagesInner: ['/event-34/image-1.jpg'],
  },
  35: {
    imageMain: 'event-35.jpg',
    imagesInner: ['/event-35/image-1.jpg'],
  },
  36: {
    imageMain: 'event-36.png',
    imagesInner: [
      '/event-36/image-1.png',
      '/event-36/image-2.png',
      '/event-36/image-3.png',
    ],
  },
  37: {
    imageMain: 'event-37.png',
    imagesInner: [
      '/event-37/image-3.jpg',
      '/event-37/image-1.jpg',
      '/event-37/image-2.jpg',
      '/event-37/image-4.jpg',
    ],
  },
  38: {
    imageMain: 'event-38.jpg',
    imagesInner: [
      '/event-38/image-1.jpg',
      '/event-38/image-2.jpg',
      '/event-38/image-3.jpg',
    ],
  },
};
