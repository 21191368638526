import React from 'react';
import styles from './SeeMoreLink.module.scss';
import cn from 'classnames';

import Link from 'next/link';
import ArrowRightIcon from 'components/blocks/Icons/Arrows/ArrowRight';
import { useIsMobile } from 'helpers/hooks/isMobile';

import Button from 'components/blocks/Button';
import stylesButton from 'components/blocks/Button/Button.module.scss';

interface SeeMoreLinkProps {
  link: string;
  text: string;
  addClasses?: string[];
}

const SeeMoreLink: React.FC<SeeMoreLinkProps> = ({
  link,
  text,
  addClasses = [],
}) => {
  const isMobile = useIsMobile(768);

  if (isMobile) {
    return (
      <Button
        href={link}
        placeholder={text}
        addStyles={[
          stylesButton.button__transparent,
          styles.seeMoreButton,
          ...addClasses,
        ]}
      />
    );
  } else {
    return (
      <Link href={link}>
        <a className={cn(styles.seeMoreLink, ...addClasses)}>
          {text}
          <ArrowRightIcon />
        </a>
      </Link>
    );
  }
};

export default SeeMoreLink;
