import { useRef } from 'react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { SwiperComponent } from 'swiper/types/shared';

export const useSlider = (additionalModules: SwiperComponent[] = []) => {
  const prevArrow = useRef(null);
  const nextArrow = useRef(null);
  const fraction = useRef(null);

  const sliderRenewParams = swiper => {
    if (!swiper.params.pagination.el) {
      swiper.params.pagination.el = fraction.current;
      swiper.pagination.update();
      swiper.params.observer = true;
      swiper.params.observeParents = true;
    }

    if (!(swiper.params.navigation.prevEl && swiper.params.navigation.nextEl)) {
      swiper.params.navigation.prevEl = prevArrow.current;
      swiper.params.navigation.nextEl = nextArrow.current;
      swiper.navigation.update();
    }
  };

  SwiperCore.use([Pagination, Navigation, ...additionalModules]);

  return { prevArrow, nextArrow, fraction, sliderRenewParams };
};
