import React from 'react';
import cn from 'classnames';

import { FeaturesSectionProps } from 'interfaces/FeaturesSection/FeaturesSection';
import { useIsMobile } from 'helpers/hooks/isMobile';
import ThumbsSlider from '../ThumbsSlider';
import { SwiperSlide } from 'swiper/react';

export const Features: React.FC<FeaturesSectionProps> = ({
  itemsDesktop,
  itemsMobile,
  itemsSpecial,
  children,
  styles,
  rootClassName,
  swiperProps = {},
}) => {
  const isMobile = useIsMobile(991);

  if (isMobile) {
    return (
      <div className={cn('container', styles[`${rootClassName}__container`])}>
        {children}
        {itemsMobile?.length > 0 && (
          <ThumbsSlider
            className={styles[`${rootClassName}__slider`]}
            spaceBetween={16}
            slidesPerView={1.11}
            {...swiperProps}
          >
            {itemsMobile.map((item, index) => {
              const itemComponent = item({ index });
              if (itemComponent === null) return null;

              return (
                <SwiperSlide
                  key={index}
                  className={cn(
                    styles[`${rootClassName}__slide`],
                    styles[`${rootClassName}__slide--${index + 1}`]
                  )}
                >
                  {itemComponent}
                </SwiperSlide>
              );
            })}
          </ThumbsSlider>
        )}
        {itemsSpecial?.map((item, index) => item({ index }))}
      </div>
    );
  } else {
    return (
      <div className='container'>
        {children}
        <div className={styles[`${rootClassName}__inner`]}>
          {itemsDesktop.map((item, index) => item({ index }))}
        </div>
      </div>
    );
  }
};
