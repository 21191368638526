import React, { useMemo, useState } from 'react';
import { EventsSingle } from 'interfaces/Events/Events';
import cn from 'classnames';

import { images } from 'helpers/eventsImages';
import Lightbox from 'yet-another-react-lightbox/dist';
import Captions from 'yet-another-react-lightbox/dist/plugins/captions';
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom';
import { Trans } from 'next-i18next';

import styles from 'components/blocks/Events/Events.module.scss';
import 'yet-another-react-lightbox/dist/styles.css';
import 'yet-another-react-lightbox/dist/plugins/captions/captions.css';
import 'yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css';

const EventsGallery: React.FC<EventsSingle> = ({
  id,
  text,
  type,
  withGallery,
  theme = 'light',
}) => {
  const [open, setOpen] = useState(false);

  const currentImage = images[id];
  const imagesLength = currentImage.imagesInner.length;

  const plugins: any[] =
    imagesLength === 1 ? [Captions, Zoom] : [Captions, Thumbnails, Zoom];

  const placeholder = useMemo(() => {
    const placeholderTypes = {
      light: {
        single: '/images/events/events-placeholder.jpg',
        double: '/images/events/events-wide-placeholder.jpg',
        square: '/images/events/events-min-placeholder.jpg',
      },
      dark: {
        single: '/images/events/events-placeholder-dark.jpg',
        double: '/images/events/events-wide-placeholder-dark.jpg',
        square: '/images/events/events-min-placeholder-dark.jpg',
      },
    };

    const themePlaceholder = placeholderTypes[theme];

    return themePlaceholder[type];
  }, [type, theme]);

  return (
    <div
      className={cn(styles.events__item, {
        [styles.events__itemNoGallery]: !withGallery,
      })}
      onClick={() => setOpen(true)}
    >
      <picture>
        <source
          srcSet={placeholder}
          data-srcset={require(`public/images/events/${currentImage.imageMain}?webp`)}
          type='image/webp'
        />
        <img
          src={placeholder}
          data-src={`/images/events/${currentImage.imageMain}`}
          alt={text}
          className={cn('img', 'lazyload', styles.events__image)}
        />
      </picture>
      <div className={styles.events__text}>
        <Trans>{text}</Trans>
      </div>
      {withGallery && (
        <Lightbox
          className={styles.lightbox}
          open={open}
          close={() => setOpen(false)}
          plugins={plugins}
          carousel={{
            finite: imagesLength < 3,
          }}
          slides={currentImage.imagesInner.map(item => {
            return {
              src: `/images/events${item}`,
              description: text.replace('<br>', ''),
            };
          })}
        />
      )}
    </div>
  );
};

export default EventsGallery;
