import { useRouter } from 'next/router';

export const useImageLang = () => {
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  return currentLanguage === 'ru' ? '' : `-${currentLanguage}`;
};
