import React, { useState } from 'react';
import cn from 'classnames';
import { Swiper } from 'swiper/react';
import { Autoplay } from 'swiper/core';
import { Trans, useTranslation } from 'next-i18next';
import { useSlider } from 'helpers/hooks/slider';
import { SwiperOptions } from 'swiper/types/swiper-options';

import styles from './Slider.module.scss';
import SeeMoreLink from '../SeeMoreLink';
import SliderArrow from '../Icons/Arrows/SliderArrow';

const BREAKPOINT_VALUES = [1, 480, 768, 992, 1280] as const;

type BreakpointValuesType = typeof BREAKPOINT_VALUES[number];

type SliderBreakpoints = {
  [key in BreakpointValuesType]?: {
    slidesPerView: number;
    slidesPerGroup: number;
    allowTouchMove?: boolean;
    spaceBetween?: number;
  };
};
interface SliderProps {
  title: string;
  spaceBetween?: number;
  breakpoints?: SliderBreakpoints;
  className?: string;
  autoHeight?: boolean;
  showMoreLink?: string;
  headerAdditionalChildren?: JSX.Element;
  options?: SwiperOptions;
}

const Slider: React.FC<SliderProps> = ({
  children,
  title,
  spaceBetween = 0,
  breakpoints = {},
  className,
  autoHeight = false,
  showMoreLink,
  headerAdditionalChildren = null,
  options = {},
}) => {
  const { t } = useTranslation(['common']);
  const { prevArrow, nextArrow, fraction, sliderRenewParams } = useSlider([
    Autoplay,
  ]);
  const [showNav, setShowNav] = useState(true);

  return (
    <div className='container'>
      <div className={styles.slider__navTitle}>
        <h2 className={cn('title-main-v2', styles.slider__title)}>
          <Trans>{title}</Trans>
        </h2>
        {showNav && (
          <div className={styles.slider__nav}>
            <div
              ref={prevArrow}
              className={cn(
                styles.slider__navArrow,
                styles.slider__navArrowPrev
              )}
            >
              <SliderArrow />
            </div>
            <div ref={fraction} className={styles.slider__fraction} />
            <div
              ref={nextArrow}
              className={cn(
                styles.slider__navArrow,
                styles.slider__navArrowNext
              )}
            >
              <SliderArrow />
            </div>
          </div>
        )}
      </div>
      {headerAdditionalChildren}
      <Swiper
        navigation={{
          prevEl: prevArrow.current,
          nextEl: nextArrow.current,
        }}
        pagination={{
          type: 'custom',
          el: fraction.current,
          renderCustom: (_, current, total) => {
            if (total === 1) {
              setShowNav(false);
            }

            return `${current} / ${total}`;
          },
        }}
        spaceBetween={spaceBetween}
        breakpoints={breakpoints}
        autoHeight={autoHeight}
        onInit={sliderRenewParams}
        onBreakpoint={sliderRenewParams}
        className={cn(className, {
          [styles.slider__sliderWithShowMore]: showMoreLink,
        })}
        {...options}
      >
        {children}
      </Swiper>
      {showMoreLink && (
        <SeeMoreLink link={showMoreLink} text={t('commonText.showMore')} />
      )}
    </div>
  );
};

export default Slider;
