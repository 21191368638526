import React from 'react';
import { Trans } from 'next-i18next';
import cn from 'classnames';
import { useIsMobile } from 'helpers/hooks/isMobile';
import {
  FeaturesItemProps,
  FeaturesItemSettings,
  FeaturesSectionSettings,
} from 'interfaces/FeaturesSection/FeaturesSection';
import Link from 'next/link';

const FeaturesItem: React.FC<FeaturesItemProps> = ({
  name,
  nameMobile,
  text,
  textMobile,
  image,
  t,
  styles,
  index,
  isSpecialBlock,
  children,
  rootClassName,
  upLevelHeadings,
  link,
}) => {
  const isMobile = useIsMobile(991);
  const realName =
      isMobile &&
      ((nameMobile !== undefined && t(nameMobile) !== nameMobile) ||
        nameMobile === null)
        ? nameMobile
        : name,
    realText =
      isMobile &&
      ((textMobile !== undefined && t(textMobile) !== textMobile) ||
        textMobile === null)
        ? textMobile
        : text;

  const advantagesContent = (
    <>
      {realName === null ? null : isMobile || upLevelHeadings ? (
        <h3 className={styles[`${rootClassName}__itemHeading`]}>
          <Trans t={t}>{t(realName)}</Trans>
        </h3>
      ) : (
        <h4 className={styles[`${rootClassName}__itemHeading`]}>
          <Trans t={t}>{t(realName)}</Trans>
        </h4>
      )}
      {realText !== null && (
        <p className={styles[`${rootClassName}__itemText`]}>
          <Trans t={t}>{t(realText)}</Trans>
        </p>
      )}
      {image &&
        (image.linkMobile ? (
          <picture className={styles[`${rootClassName}__itemImage`]}>
            <source srcSet={image.linkMobile} media='(max-width: 991px)' />
            <source
              srcSet={
                image.linkRetina
                  ? `${image.link} 1x, ${image.linkRetina} 2x`
                  : image.link
              }
            />
            <img
              className={styles[`${rootClassName}__itemImageInner`]}
              src={image.link}
              srcSet={
                image.linkRetina
                  ? `${image.link} 1x, ${image.linkRetina} 2x`
                  : image.link
              }
              alt={t(image.alt)}
            />
          </picture>
        ) : (
          <img
            className={styles[`${rootClassName}__itemImage`]}
            src={image.link}
            srcSet={
              image.linkRetina
                ? `${image.link} 1x, ${image.linkRetina} 2x`
                : image.link
            }
            alt={t(image.alt)}
          />
        ))}
    </>
  );

  let content: JSX.Element;
  if (isMobile) {
    if (isSpecialBlock) return null;

    content = (
      <article
        className={cn(
          styles[`${rootClassName}__item`],
          styles[`${rootClassName}__item--${index + 1}`]
        )}
      >
        {advantagesContent}
        {children}
      </article>
    );
  } else {
    content = (
      <article
        className={cn(
          styles[`${rootClassName}__item`],
          styles[`${rootClassName}__item--${index + 1}`]
        )}
        key={realName}
      >
        {advantagesContent}
        {children}
      </article>
    );
  }

  if (link) {
    return <Link href={link}>{content}</Link>;
  } else return content;
};

export const renderFunctionFromItems = (props: {
  items: Array<FeaturesItemSettings | null>;
  settings: FeaturesSectionSettings;
  Component?: React.FC<FeaturesItemProps>;
  upLevelHeadings?: boolean;
}) => {
  return props.items.map(item => {
    if (item === null) return () => null;

    const RenderItem = ({ index }: { index: number }) =>
      props.Component ? (
        <props.Component
          {...item}
          {...props.settings}
          upLevelHeadings={props.upLevelHeadings}
          index={index}
          key={index}
        />
      ) : (
        <FeaturesItem
          {...item}
          {...props.settings}
          index={index}
          key={index}
          upLevelHeadings={props.upLevelHeadings}
        />
      );

    return RenderItem;
  });
};

export const stripItemIndexes = <T,>(
  items: T[],
  stripIndexes: number[]
): Array<T | null> =>
  items.map((item, i) => (stripIndexes.includes(i) ? null : item));
export const keepItemIndexes = <T,>(
  items: T[],
  keepIndexes: number[]
): Array<T | null> =>
  items.map((item, i) => (keepIndexes.includes(i) ? item : null));

export default FeaturesItem;
