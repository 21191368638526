import React, { useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import cn from 'classnames';
import { OrganizersFeedbackData } from 'interfaces/Feedback/Feedback';
import { useFormValidate } from 'helpers/hooks/form';

import { Form, Formik } from 'formik';
import Button from 'components/blocks/Button';
import FormInput from 'components/blocks/FormInput';

import styles from './FeedbackForm.module.scss';
import buttonStyles from 'components/blocks/Button/Button.module.scss';
import { genericAnchors } from 'helpers/anchors';

interface FeedbackFormProps {
  id?: string;
  content: {
    heading: string;
    subheading: string;
    subheadingWithinForm?: boolean;
    items?: string[];
  };
  image: JSX.Element;
  additionalButton?: JSX.Element;
  page: string;
  buttonText: string;
  additionalClass?: string;
  additionalStyles?: Record<string, string>;
  onSubmit(data: OrganizersFeedbackData, actions: any): Promise<void>;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  onSubmit,
  id = genericAnchors.feedbackForm,
  content,
  image,
  page,
  buttonText,
  additionalClass,
  additionalStyles = {},
  additionalButton = null,
}) => {
  const { t } = useTranslation('feedback');
  const [nameError, setNameError] = useState();
  const [surnameError, setSurnameError] = useState();
  const [emailError, setEmailError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [organizationError, setOrganizationError] = useState();

  const handleValidate = useFormValidate({
    emailError: setEmailError,
    nameError: setNameError,
    phoneError: setPhoneError,
    surnameError: setSurnameError,
    organizationError: setOrganizationError,
  });

  const hasAdditionalClass = Boolean(
    additionalClass && Object.keys(additionalStyles).length
  );

  const subheading = (
    <p
      className={cn(styles.form__subheading, {
        [additionalStyles[`${additionalClass}__subheading`] ?? '']:
          hasAdditionalClass,
      })}
    >
      <Trans>{content.subheading}</Trans>
    </p>
  );

  return (
    <section id={id} className='content'>
      <div className='container'>
        {content.subheading && !content.subheadingWithinForm && subheading}
        <h2
          className={cn(styles.form__heading, {
            [additionalStyles[`${additionalClass}__heading`] ?? '']:
              hasAdditionalClass,
          })}
        >
          <Trans>{content.heading}</Trans>
        </h2>
        {content.items?.length > 0 && (
          <ul
            className={cn(styles.form__list, {
              [additionalStyles[`${additionalClass}__list`] ?? '']:
                hasAdditionalClass,
            })}
          >
            {content.items.map(item => (
              <li
                key={item}
                className={cn(styles.form__point, {
                  [additionalStyles[`${additionalClass}__point`] ?? '']:
                    hasAdditionalClass,
                })}
              >
                <Trans>{item}</Trans>
              </li>
            ))}
          </ul>
        )}
        <div
          className={cn(styles.form, {
            [additionalStyles[additionalClass]]: hasAdditionalClass,
          })}
        >
          <div
            className={cn(styles.form__inner, {
              [additionalStyles[`${additionalClass}__inner`] ?? '']:
                hasAdditionalClass,
            })}
          >
            <div
              className={cn(styles.form__info, {
                [additionalStyles[`${additionalClass}__info`] ?? '']:
                  hasAdditionalClass,
              })}
            >
              {image}
            </div>
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                surname: '',
                organization: '',
                page,
              }}
              validate={handleValidate}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldTouched }) => (
                <Form
                  className={cn(styles.form__form, {
                    [additionalStyles[`${additionalClass}__form`] ?? '']:
                      hasAdditionalClass,
                  })}
                  noValidate
                >
                  {content.subheading &&
                    content.subheadingWithinForm &&
                    subheading}
                  <FormInput
                    type='name'
                    name='name'
                    placeholder={t('feedback.nameField.nameLabel', {
                      ns: 'feedback',
                    })}
                    error={nameError}
                    onBlur={() => setFieldTouched('name')}
                  />
                  <FormInput
                    type='text'
                    name='surname'
                    placeholder={t('feedback.surnameField.label', {
                      ns: 'feedback',
                    })}
                    error={surnameError}
                    onBlur={() => setFieldTouched('surname')}
                  />
                  <FormInput
                    type='email'
                    name='email'
                    placeholder={t('feedback.emailField.emailLabel', {
                      ns: 'feedback',
                    })}
                    error={emailError}
                    onBlur={() => setFieldTouched('email')}
                  />
                  <FormInput
                    type='tel'
                    name='phone'
                    placeholder={t('feedback.phoneField.phoneLabel', {
                      ns: 'feedback',
                    })}
                    error={phoneError}
                    onBlur={() => setFieldTouched('phone')}
                  />
                  <FormInput
                    type='text'
                    name='organization'
                    placeholder={t('feedback.organizationField.label', {
                      ns: 'feedback',
                    })}
                    error={organizationError}
                    onBlur={() => setFieldTouched('organization')}
                  />
                  <div
                    className={cn(styles.form__checkPrivacy, {
                      [additionalStyles[`${additionalClass}__checkPrivacy`] ??
                      '']: hasAdditionalClass,
                    })}
                  >
                    <label
                      className={cn(styles.form__privacyLabel, {
                        [additionalStyles[`${additionalClass}__privacyLabel`] ??
                        '']: hasAdditionalClass,
                      })}
                    >
                      <span>
                        {t('feedback.privacyCheckbox.privacyLabel', {
                          ns: 'feedback',
                        })}
                        <a
                          href='/privacy'
                          className={cn('link', 'text-green')}
                          target='_blank'
                        >
                          {t('feedback.privacyCheckbox.privacyLabelLink', {
                            ns: 'feedback',
                          })}
                        </a>
                      </span>
                    </label>
                  </div>
                  <div
                    className={cn(styles.form__buttons, {
                      [additionalStyles[`${additionalClass}__buttons`] ?? '']:
                        hasAdditionalClass,
                    })}
                  >
                    {additionalButton}
                    <Button
                      placeholder={buttonText}
                      disabled={isSubmitting}
                      type='submit'
                      addStyles={[
                        styles.form__button,
                        buttonStyles.button__mobH48,
                        additionalStyles[`${additionalClass}__button`],
                      ].filter(Boolean)}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeedbackForm;
