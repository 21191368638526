import React from 'react';
import cn from 'classnames';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper } from 'swiper/react';

import styles from './ThumbsSlider.module.scss';

interface ThumbsSliderProps {
  className?: string;
  spaceBetween?: number;
  slidesPerView?: number | 'auto';
}

const ThumbsSlider: React.FC<ThumbsSliderProps> = props => {
  SwiperCore.use([Pagination, Navigation]);

  return (
    <Swiper
      pagination={{
        type: 'bullets',
        bulletClass: styles.slider__bullet,
        bulletActiveClass: styles.slider__bulletActive,
        clickable: true,
        clickableClass: styles.slider__pagination,
      }}
      spaceBetween={props.spaceBetween}
      className={cn(styles.slider, props.className)}
      slidesPerView={props.slidesPerView}
      breakpoints={{
        768: {
          slidesPerView: 1.5,
        },
      }}
    >
      {props.children}
    </Swiper>
  );
};

export default ThumbsSlider;
