import React, { useMemo } from 'react';
import { IReviewItem } from 'services/homepageService';
import { useTranslation } from 'next-i18next';
import { useIsMobile } from 'helpers/hooks/isMobile';
import { chunkArray, chunkArrayColumns } from 'helpers/chunkArray';
import cn from 'classnames';

import ReviewItem from 'components/blocks/Reviews/ReviewItem';
import VerticalSlider from 'components/blocks/VerticalSlider';
import { SwiperSlide } from 'swiper/react';
import Slider from 'components/blocks/Slider';
import SeeMoreLink from 'components/blocks/SeeMoreLink';

import styles from './ReviewsSlider.module.scss';

interface ReviewProps {
  data: IReviewItem[];
}

const ReviewsSlider: React.FC<ReviewProps> = ({ data }: ReviewProps) => {
  const { t } = useTranslation('reviews');
  const isMobile = useIsMobile(768);
  const isTablet = useIsMobile(992);

  const reviewSlides = useMemo(() => {
    const array = chunkArray(data, 4);

    if (isTablet) {
      return array.map(item => {
        return chunkArrayColumns(item, 1);
      });
    }

    return array.map(item => {
      return chunkArrayColumns(item, 2);
    });
  }, []);

  return (
    <section className='content' id='reviews'>
      {isMobile ? (
        <Slider
          title={t('reviews.title')}
          spaceBetween={20}
          autoHeight={true}
          showMoreLink='/reviews'
        >
          {data?.map(
            (item, i) =>
              item && (
                <SwiperSlide key={i}>
                  <ReviewItem {...item} />
                </SwiperSlide>
              )
          )}
        </Slider>
      ) : (
        <VerticalSlider
          heading={t('reviews.title')}
          text={
            <p className={styles.reviewsSlider__description}>
              {t('reviews.subtitle')}
            </p>
          }
          spaceBetween={56}
          rowReverse={true}
          link={
            <SeeMoreLink
              link='/reviews'
              text={t('reviews.showAll')}
              addClasses={[styles.reviewsSlider__link]}
            />
          }
          customSliderClass={styles.reviewsSlider}
          customInfoClass={styles.reviewsSlider__info}
        >
          {reviewSlides.map((slide, s) => (
            <SwiperSlide key={s}>
              <div
                className={cn(styles.reviewsSlider__slide, {
                  [styles.reviewsSlider__slideSingle]: slide.length === 1,
                })}
              >
                {slide.map((column, c) => (
                  <div
                    className={styles.reviewsSlider__column}
                    key={`${c}${s}`}
                  >
                    {column.map(
                      (item, i) =>
                        item && <ReviewItem key={`${i}${c}${s}`} {...item} />
                    )}
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </VerticalSlider>
      )}
    </section>
  );
};

export default ReviewsSlider;
