import { reviewsData } from 'helpers/reviewsItems';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'stores/store';
import { IUserStore } from 'stores/userStore';

export const useExtractedReviews = (
  reviews: string[],
  englishVersion?: string[]
) => {
  const { t } = useTranslation('reviews');
  const userStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = userStored.geoData;

  let newReviews = reviews;
  if (geoDataStored !== 'RU' && englishVersion) {
    newReviews = englishVersion;
  }

  return newReviews.map(review => {
    return {
      photo: reviewsData[review].image,
      text: `reviews.items.${review}.text`,
      name: t(`reviews.items.${review}.name`),
      place: `reviews.items.${review}.place`,
      flag: reviewsData[review].flag,
      links: t(`reviews.items.${review}.links`, {
        ns: 'reviews',
        returnObjects: true,
      }) as string[],
    };
  });
};
