import React from 'react';

const ArrowRightIcon: React.FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M34.489 22.0857L22.2906 9.88726L25.119 7.05884L42.0896 24.0294L25.119 41L22.2906 38.1715L34.3764 26.0857H6.02344L6.02344 22.0857H34.489Z'
        fill='white'
      />
    </svg>
  );
};

export default ArrowRightIcon;
