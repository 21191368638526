import { AxiosError, AxiosResponse } from 'axios';
import { httpClient } from 'helpers/httpClient';
import { NoAnswer } from 'interfaces/Feedback/Feedback';
import { OrganizersFeedbackData } from 'interfaces/Feedback/Feedback';

export const sendFeedback = async (data): Promise<AxiosResponse<NoAnswer>> => {
  try {
    const response = await httpClient.post<NoAnswer>(
      `/organizer_statement?locale=${data.locale}`,
      data.data
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const submitOrganizersFeedback = async (
  data: OrganizersFeedbackData,
  actions,
  { language, successCallback, errorCallback }
) => {
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    data: {
      organizer: {
        first_name: data.name,
        last_name: data.surname,
        email: data.email,
        phone: data.phone,
        organization: data.organization ? data.organization : '',
        page: data.page,
      },
    },
  };
  const response = await sendFeedback(finalData);
  setSubmitting(false);

  if (!response?.status) {
    errorCallback({});
    return;
  }

  switch (response.status) {
    case 200:
    case 204:
      successCallback();
      resetForm({});
      break;

    default:
      errorCallback(response.data);
      break;
  }
};
